import { Button, Image } from "components/commons";
import { locale } from "localization/en";
import { formatAmount, formatDate } from "utils";
import { DateTime } from "enums";
import styles from "./credit-accounts.module.scss";
import { Warning } from "images";

export const mapDataToList = ({ billings = {}, handleGoToValidatePayment, onClickSOAView }) => {
  const {
    // fleetId,
    amountPaid,
    dueDate,
    fleet,
    billingAmount,
  } = billings;

  return {
    businessName: fleet?.businessName,
    driversCreditLimit: formatAmount(fleet.creditLimit),
    fleetAvailableCredit:
      fleet.availableLimit < 5000 ? (
        <div className={styles.lessAvailableCredit}>
          <Image src={Warning} />
          {formatAmount(fleet.availableLimit)}
        </div>
      ) : (
        formatAmount(fleet.availableLimit)
      ),
    latestBillingStatement: formatAmount(billingAmount),
    dueDate: formatDate(dueDate, DateTime.M),
    amountPaid: formatAmount(amountPaid),
    transact: (
      <>
        <Button
          primary
          disabled={fleet.availableLimit <= 5000}
          onClick={() => {
            handleGoToValidatePayment();
          }}
        >
          {locale.gasUp}
        </Button>
      </>
    ),
    // statementOfAccount: (
    //   <>
    //     <Button link onClick={() => onClickSOAView?.(fleetId)}>
    //       {locale.view}
    //     </Button>
    //   </>
    // ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { fleetStatus, status, ...fs } = filterState;
  return {
    ...fs,
    status: status.length > 0 ? status.join(",") : null,
    fleetStatus: fleetStatus.length === 1 ? fleetStatus.join(",") : null,
  };
};
